import React from 'react';

import cx from 'classnames';

import { Icon } from '../Icons';

import css from './NotificationBadge.module.css';

export type NotificationBadgeType = 'decline' | 'join';

interface NotificationBadgeProps {
  type?: NotificationBadgeType;
  className?: string;
}

const BadgeIcon = React.memo<NotificationBadgeProps>(({ type, className }) => {
  if (type === 'join') {
    return <Icon.Plus2 className={className} />;
  }

  if (type === 'decline') {
    return <Icon.Plus2 className={className} />;
  }

  return null;
});

export const NotificationBadge = React.memo<NotificationBadgeProps>(
  ({ type = 'join', className }) => {
    return (
      <div className={cx(css.containerWrapper, className)}>
        <div
          className={cx(css.container, {
            [css.declineType]: type === 'decline',
            [css.joinType]: type === 'join',
          })}
        >
          <BadgeIcon className={css.badgeIcon} type={type} />
        </div>
      </div>
    );
  },
);
