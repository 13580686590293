import React from 'react';

import { Button } from '../Button';
import { Text } from '../Text';

import css from './Modal.module.css';

import { Modal } from './index';

interface Action {
  text: string;
  onAction: (event: React.MouseEvent) => void;
}
interface ModalProps {
  isOpen?: boolean;
  onRequestClose?: () => void;
  loading?: boolean;

  text: string;
  action: { yes: Action; no?: Action };
}

export const ConfirmationModal = React.memo<ModalProps>(
  ({ isOpen, text, action, loading = false, onRequestClose = () => undefined }) => {
    return (
      <Modal
        isOpen={isOpen}
        modalClassName={css.confirmContainer}
        overlayClassName={css.confirmOverlay}
        withCloseIcon={false}
        onRequestClose={onRequestClose}
      >
        <Text.R3 className={css.confirmationText}>{text}</Text.R3>
        <div className={css.buttonsWrapper}>
          {action.no ? (
            <Button
              className={css.buttonContainer}
              color="secondary"
              onClick={action.no.onAction}
            >
              {action.no.text}
            </Button>
          ) : null}
          <Button
            className={css.buttonContainer}
            loading={loading}
            onClick={action.yes.onAction}
          >
            {action.yes.text}
          </Button>
        </div>
      </Modal>
    );
  },
);
