import { useCallback, useEffect, useRef, useState } from 'react';

const useDebounceWithImmediate = <F extends (...args: any[]) => any>(
  func: F,
  delay: number,
) => {
  const lastExecTimeRef = useRef(0);
  const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const debouncedFunc = useCallback(
    (...args: Parameters<F>) => {
      const now = Date.now();
      if (lastExecTimeRef.current === 0) {
        lastExecTimeRef.current = now;
        func(...args);
      } else if (now - lastExecTimeRef.current >= delay) {
        lastExecTimeRef.current = now;
        func(...args);
      } else {
        if (timeoutIdRef.current) {
          clearTimeout(timeoutIdRef.current);
        }
        timeoutIdRef.current = setTimeout(() => {
          lastExecTimeRef.current = Date.now();
          func(...args);
        }, delay - (now - lastExecTimeRef.current));
      }
    },
    [func, delay],
  );

  return debouncedFunc;
};

export const useClickSpamProtection = (handleClick: () => void, delay: number) => {
  const [isClickAllowed, setIsClickAllowed] = useState(true);
  const debouncedSetIsClickAllowed = useDebounceWithImmediate(
    setIsClickAllowed,
    delay,
  );

  useEffect(() => {
    if (!isClickAllowed) {
      debouncedSetIsClickAllowed(true);
    }
  }, [isClickAllowed, debouncedSetIsClickAllowed]);

  const handleClickWithSpamProtection = useCallback(() => {
    if (!isClickAllowed) {
      // detecred spam
      return;
    }

    setIsClickAllowed(false);
    handleClick();
  }, [isClickAllowed, handleClick]);

  return handleClickWithSpamProtection;
};
