import React, { HTMLAttributes } from 'react';

import Link from 'next/link';

import { links } from 'utils/links';

import { CloseIcon, IconProps } from '../Icons__deprecated';

import css from './Utils.module.css';

export const Gap: React.FC<{
  width?: number;
  height?: number;
  minWidth?: number;
}> = ({ width = 0, height = 0, minWidth }) => {
  return (
    <div
      style={{
        ...(width && { width, minWidth: width }),
        ...(height && { height }),
        ...(minWidth && { minWidth }),
      }}
    />
  );
};

export const Flex: React.FC<{
  alignCenter?: boolean;
  justifyCenter?: boolean;
  height?: number | string;
  children?: JSX.Element | false | null | string | Array<any>;
}> = ({ children, alignCenter, justifyCenter, height }) => {
  return (
    <div
      style={{
        display: 'flex',
        ...(alignCenter && { alignItems: 'center' }),
        ...(justifyCenter && { justifyContent: 'center' }),
        ...(height && { height }),
      }}
    >
      {children}
    </div>
  );
};

export const FlexGrow: React.FC<{
  grow?: number;
}> = ({ grow = 1 }) => {
  return <div style={{ flexGrow: grow }} />;
};

export const Block: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = props => {
  return <div className={css.block} {...props} />;
};

export const LinkUser: React.FC<
  {
    username: string;
  } & Omit<HTMLAttributes<HTMLAnchorElement>, 'href'>
> = ({ children, username, ...props }) => {
  return (
    <Link href={links.user.home(username)} {...props}>
      {children}
    </Link>
  );
};

export const DeleteIcon: React.FC<IconProps> = props => {
  return <CloseIcon className={css.deleteIcon} fill="white" {...props} />;
};
