import React, { useCallback, useMemo, useState } from 'react';

import { PureQueryOptions } from '@apollo/client';
import cx from 'classnames';

import { analytics } from 'analytics';
import { PlayerCategory } from 'analytics/playerFeed';
import { api, refetchMyCounters, refetchEvents, useMe, optimistic } from 'api';
import { useOnboardingModal } from 'components/ContextModal/onboarding';
import { useText } from 'text';

import { Button, ButtonIcon } from '../Button';
import { Icon as AppIcon, Icon } from '../Icons';
import { ConfirmationModal } from '../Modal';
import { useToasts } from '../Toast';

import { useClickSpamProtection } from './helpers';

import css from './UserCard.module.css';

interface ButtonFollowProps {
  isFollowing: boolean;
  onClick: () => void;
  loading?: boolean;
  className?: string;
  Icon?: AppIcon.IconType;
  label?: string;
}

const ButtonFollowWithIcon = React.memo<ButtonFollowProps>(
  ({ isFollowing, Icon, onClick, loading, className }) => {
    return (
      <ButtonIcon
        className={className}
        Icon={Icon || (isFollowing ? AppIcon.UserFollowed : AppIcon.UserAdd)}
        iconClassName={cx(css.buttonFollowIcon, {
          [css.buttonIsFollowingIcon]: isFollowing,
        })}
        loading={loading}
        onClick={event => {
          onClick();

          //stopPropagation is to avoid clicking on a link card below
          event.stopPropagation();
        }}
      />
    );
  },
);

const ButtonFollow = React.memo<ButtonFollowProps>(
  ({ isFollowing, onClick, loading, label, className }) => {
    const text = useText(state => state.controls);

    const buttonText = useMemo(() => {
      if (label) {
        return label;
      }

      return isFollowing
        ? text.followers.actionUnfollow
        : text.followers.actionFollow;
    }, [isFollowing, label]);

    return (
      <Button
        className={cx(
          css.buttonFollowWithText,
          {
            [css.buttonIsFollowingWithText]: isFollowing,
          },
          className,
        )}
        color="green"
        labelClassName={css.labelClassName}
        loading={loading}
        onClick={event => {
          onClick();

          //stopPropagation is to avoid clicking on a link card below
          event.stopPropagation();
        }}
      >
        <div className={css.buttonChildren}>
          <Icon.ProfilePlus className={css.followButtonIcon} />
          {buttonText}
        </div>
      </Button>
    );
  },
);

interface ButtonFollowUserProps {
  label?: string;
  user: api.User;
  withText?: boolean;
  profile?: boolean;
  playerEventCategory: PlayerCategory;
  className?: string;
  refetchQueries?: PureQueryOptions[];
  Icon?: AppIcon.IconType;

  isRefetchEvents?: boolean;
  onComplete?: (actionFollow: boolean) => Promise<unknown> | unknown;
}
export const ButtonFollowUser = React.memo<ButtonFollowUserProps>(
  ({
    user,
    label,
    profile,
    withText,
    className,
    onComplete = () => undefined,
    refetchQueries = [],
    playerEventCategory,
    isRefetchEvents = true,
    Icon,
  }) => {
    const modalText = useText(state => state.user.unfollowConfirmModal);
    const toasts = useToasts();
    const me = useMe();
    const onboardingModal = useOnboardingModal();

    const [isConfirmOpen, setIsConfirmOpen] = useState(false);

    const from = new Date();
    from.setHours(0, 0, 0, 0);

    const refetchQueriesEvents = useMemo(() => {
      if (!isRefetchEvents) {
        return [];
      }

      return [refetchEvents(from)];
    }, [isRefetchEvents]);

    const [follow] = api.useFollowUserMutation({
      onError: error => toasts.showError(error, api.FollowUserDocument),
      onCompleted: () => onComplete(true),
      refetchQueries: [refetchMyCounters, ...refetchQueriesEvents, ...refetchQueries],
    });
    const [unfollow] = api.useUnfollowUserMutation({
      onError: error => toasts.showError(error, api.UnfollowUserDocument),
      onCompleted: () => onComplete(false),
      refetchQueries: [refetchMyCounters, ...refetchQueriesEvents, ...refetchQueries],
    });

    const onClickFollow = useCallback(async () => {
      setIsConfirmOpen(false);
      if (!me) {
        onboardingModal.open({ title: undefined });

        return;
      }

      if (user.isFollowing) {
        analytics.clickUnfollow({
          category: playerEventCategory,
          additionalParams: {
            profileId: user.id,
            profileUsername: user.username,
            isStreamer: String(user?.roles?.includes(api.UserRole.Streamer)),
          },
        });
        await unfollow({
          variables: { userId: user.id },
          optimisticResponse: optimistic.unfollowUser(user),
        });
      } else {
        analytics.clickFollow({
          category: playerEventCategory,
          additionalParams: {
            profileId: user.id,
            profileUsername: user.username,
            isStreamer: String(user?.roles?.includes(api.UserRole.Streamer)),
          },
        });

        await follow({
          variables: { userId: user.id },
          optimisticResponse: optimistic.followUser(user),
        });
      }
    }, [me, user.isFollowing, user.id, user.username, playerEventCategory]);

    const debouncedHandleClick = useClickSpamProtection(onClickFollow, 3000);

    const renderButton = () => {
      if (withText || Boolean(label)) {
        return (
          <ButtonFollow
            className={cx(css.buttonFollowUser, className)}
            isFollowing={user.isFollowing || false}
            label={label}
            loading={isConfirmOpen}
            onClick={() => {
              if (profile && user.isFollowing) {
                setIsConfirmOpen(true);

                return;
              }
              debouncedHandleClick();
            }}
          />
        );
      }

      return (
        <ButtonFollowWithIcon
          className={cx(css.buttonFollowUser, className)}
          Icon={Icon}
          isFollowing={user.isFollowing || false}
          loading={isConfirmOpen}
          onClick={() => {
            if (profile && user.isFollowing) {
              setIsConfirmOpen(true);

              return;
            }
            debouncedHandleClick();
          }}
        />
      );
    };

    if (user.id === me?.id) {
      return null;
    }

    return (
      <>
        {renderButton()}
        <ConfirmationModal
          action={{
            yes: {
              text: modalText.yes,
              onAction: onClickFollow,
            },
            no: {
              text: modalText.no,
              onAction: () => setIsConfirmOpen(false),
            },
          }}
          isOpen={isConfirmOpen}
          text={modalText.title(user.username)}
          onRequestClose={() => setIsConfirmOpen(false)}
        />
      </>
    );
  },
);
