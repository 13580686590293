import React from 'react';

import { useRouter } from 'next/router';

import { utils } from 'utils';

import {
  Pagination as Component,
  PaginationProps as ComponentProps,
} from './Pagination';

type PaginationProps = Omit<ComponentProps, 'offset'>;

export const Pagination = React.memo<PaginationProps>(({ children, ...props }) => {
  const router = useRouter();

  const offset = utils.getQueryInt(router, 'offset');

  return (
    <Component offset={offset} {...props}>
      {children}
    </Component>
  );
});
