import React, { useRef, useState } from 'react';

import cx from 'classnames';
import Link from 'next/link';

import { utils } from 'utils';

import { Icon } from '../Icons';
import { useText } from '../Language';
import { Text } from '../Text';

import css from './Pagination.module.css';

const options: IntersectionObserverInit = {
  root: null,
  rootMargin: '400px',
};

/**
 * For means of Apollo there is
 * no need to assign data, it is handled
 * automatically
 */

interface PagesProps {
  count: number;
  offset: number;
  limit?: number;
}

export interface PaginationProps extends PagesProps {
  children: React.ReactNode | React.ReactNode[];
  onRequestMore?: () => unknown | Promise<unknown>;
  hasMore: boolean | undefined;
}
export const Pagination = React.memo<PaginationProps>(
  ({ children, hasMore, onRequestMore, ...props }) => {
    const text = useText(state => state.controls);

    const isSsr = false;
    // const isSsr = typeof window === 'undefined';

    // isLoading avoids merging same chunk twice
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const loaderRef = useRef<HTMLDivElement | null>(null);

    utils.useIntersectionObserver(
      options,
      loaderRef,
      async () => {
        if (!onRequestMore || isLoading) {
          return;
        }

        setIsLoading(true);
        await onRequestMore();
        setIsLoading(false);
      },
      true,
      [onRequestMore, isLoading],
    );

    const renderPagination = (): React.ReactNode => {
      if (!hasMore) {
        return null;
      }

      return (
        <div ref={loaderRef} className={css.loaderWrapper}>
          <Text.Regular
            semibold
            size={3}
            onClick={async () => {
              if (isLoading || !onRequestMore) {
                return;
              }
              setIsLoading(true);
              await onRequestMore();
              setIsLoading(false);
            }}
          >
            {isLoading ? text.loading : text.actions.showMore}
          </Text.Regular>
          {!isLoading && <Icon.ArrowDown className={css.iconMore} />}
        </div>
      );
    };

    return (
      <>
        {children}
        {isSsr ? <Pages {...props} /> : renderPagination()}
      </>
    );
  },
);

export const Pages = React.memo<PagesProps>(({ count, limit = 10, offset }) => {
  const amount = Math.ceil(count / limit);
  const current = Math.floor(offset / limit) + 1;

  return (
    <div className={css.container}>
      {mapNumbers(amount, current).map(page => (
        <Page key={page} isCurrent={page === current} limit={limit} page={page} />
      ))}
    </div>
  );
});

interface PageProps {
  page: number;
  limit: number;
  isCurrent: boolean;
}
const Page = React.memo<PageProps>(({ page, limit, isCurrent }) => {
  const offset = (page - 1) * limit;

  if (isCurrent) {
    return (
      <Text.Regular bold className={cx(css.pageWrapper, css.pageActive)} Tag="b">
        {page}
      </Text.Regular>
    );
  }

  return (
    <Link href={!offset ? '/' : `?offset=${offset}`}>
      <Text.Regular
        bold
        className={cx(css.pageWrapper, { [css.pageActive]: isCurrent })}
      >
        {page}
      </Text.Regular>
    </Link>
  );
});

function mapNumbers(amount: number, current: number): number[] {
  const near = Array.from({ length: 10 }, (_, index) => index + current);

  const pages = [...near];

  if (!pages.includes(1)) {
    pages.unshift(1);
  }

  const biggest = pages[pages.length - 1];
  // if biggest page is 10 and total is 100, then first %10 are covered
  const coveredArea = biggest / amount;

  const points = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1];
  points.forEach(percent => {
    if (coveredArea >= percent) {
      return;
    }
    const page = Math.floor(amount * percent);

    if (!pages.includes(page)) {
      pages.push(page);
    }
  });

  return pages;
}
