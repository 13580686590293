import React from 'react';

import { PureQueryOptions } from '@apollo/client';

import { PlayerCategory } from 'analytics/playerFeed';
import { api, Iterator } from 'api';

import { Layout } from '../Layout';
import { Pagination } from '../Pagination';

import { UserCard } from './UserCard';

import css from './UserCard.module.css';

interface UserCardListProps {
  users: Iterator<api.User[]>;
  me: api.Maybe<api.User>;
  playerEventCategory: PlayerCategory;

  renderEmpty: () => React.ReactElement;
  isLoading: boolean;
  onRequestMore?: () => Promise<unknown> | unknown;
  refetchQueries?: PureQueryOptions[];
}

export const UserCardList = React.memo<UserCardListProps>(
  ({
    users,
    me,
    refetchQueries,
    renderEmpty,
    isLoading,
    playerEventCategory,
    onRequestMore = () => undefined,
  }) => {
    const renderList = (userList: api.User[]): React.ReactNode[] => {
      return userList.map((user, index) => (
        <UserCard
          key={user.id}
          isLast={index === userList.length - 1}
          isMe={me?.id === user.id}
          playerEventCategory={playerEventCategory}
          refetchQueries={refetchQueries}
          user={user}
        />
      ));
    };

    if (users?.data) {
      if (users.data.length === 0) {
        return renderEmpty();
      }

      return (
        <Pagination
          count={users.count}
          hasMore={users.after}
          limit={users.limit}
          onRequestMore={onRequestMore}
        >
          <section className={css.cardList}>{renderList(users.data)}</section>
        </Pagination>
      );
    }

    if (isLoading) {
      return <Layout.Loading />;
    }

    return null;
  },
);
