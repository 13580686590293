import React from 'react';

import { PureQueryOptions } from '@apollo/client';
import cx from 'classnames';

import { formatDistance } from 'date-fns';

import { analytics } from 'analytics';
import { PlayerCategory } from 'analytics/playerFeed';
import { api } from 'api';

import { AvatarUser } from '../Avatar';
import { Icon } from '../Icons';
import { useText } from '../Language';
import { Text } from '../Text';
import { LinkUser } from '../Utils/Utils';

import css from './UserCard.module.css';

import { ButtonFollowUser } from './index';

export interface UserCardProps {
  user: api.User;
  playerEventCategory: PlayerCategory;
  isMe?: boolean;
  refetchQueries?: PureQueryOptions[];
  isLast?: boolean;
}

export const UserCard = React.memo<UserCardProps>(
  ({ user, isMe = false, isLast = false, refetchQueries, playerEventCategory }) => {
    const text = useText(state => state.userSearch.card);

    const renderVisitorInfo = () => {
      if (!user.createdAt) {
        return null;
      }
      const createdAt = new Date(user.createdAt);

      return `, ${formatDistance(createdAt, new Date(), {
        addSuffix: true,
      })}`;
    };

    return (
      <div className={css.userCardContainer}>
        <section className={css.infoContainer}>
          <LinkUser
            className={css.cardAvatarContainer}
            username={user.username}
            onClick={() => {
              analytics.clickPlayer({
                category: playerEventCategory,
                additionalParams: {
                  profileId: user.id,
                  profileUsername: user.username,
                },
              });
            }}
          >
            <AvatarUser className={css.avatar} user={user} />
          </LinkUser>
          <article className={cx(css.secondaryInfo, { [css.lastContainer]: isLast })}>
            <div className={css.titlesContainer}>
              <LinkUser
                className={css.usernameWrapper}
                username={user.username}
                onClick={() => {
                  analytics.clickPlayer({
                    category: playerEventCategory,
                    additionalParams: {
                      profileId: user.id,
                      profileUsername: user.username,
                    },
                  });
                }}
              >
                <Text.Regular semibold>{user.username}</Text.Regular>
                {user.roles?.includes(api.UserRole.Streamer) ? (
                  <Icon.StreamerCheck className={css.streamerCheck} />
                ) : null}
              </LinkUser>
              <div className={css.statContainer}>
                {/* <PashaRatingBadge amount={user.rating} /> */}
                <Text.Regular className={css.statOption} size={3}>
                  {text.followers(user.followersCount || 0)}
                  {playerEventCategory === 'visitorsTab' && (
                    <>{renderVisitorInfo()}</>
                  )}
                </Text.Regular>
              </div>
            </div>

            {!isMe && (
              <ButtonFollowUser
                playerEventCategory={playerEventCategory}
                refetchQueries={refetchQueries}
                user={user}
              />
            )}
          </article>
        </section>
      </div>
    );
  },
);
