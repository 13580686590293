import React, { useEffect } from 'react';

import cx from 'classnames';

import { Icon } from '../Icons';
import { Portal } from '../Portal';

import css from './Modal.module.css';

interface ModalProps {
  isOpen?: boolean;
  onRequestClose?: (event?: React.MouseEvent | KeyboardEvent) => void;

  modalClassName?: string;
  overlayClassName?: string;
  modalFooter?: () => React.ReactNode;
  withCloseIcon?: boolean;
  canCloseOutside?: boolean;

  children?: JSX.Element | JSX.Element[] | null | string | false;
}
export const Modal: React.FC<ModalProps> = React.memo(
  ({
    children,
    modalClassName,
    overlayClassName,
    isOpen = false,
    onRequestClose = () => undefined,
    modalFooter = () => null,
    withCloseIcon = true,
    canCloseOutside = true,
  }) => {
    useEffect(() => {
      if (!isOpen) {
        document.body.style.overflowY = 'auto';

        return;
      }

      document.body.style.overflowY = 'hidden';

      function listenToEsc(event: KeyboardEvent): void {
        if (event.key === 'Escape') {
          onRequestClose(event);
        }
      }
      document.addEventListener('keydown', listenToEsc);

      return () => document.removeEventListener('keydown', listenToEsc);
    }, [isOpen]);

    return (
      <Portal>
        <section
          className={cx(css.overlay, { [css.overlayOpen]: isOpen }, overlayClassName)}
          onClick={() => {
            if (!canCloseOutside) {
              return;
            }
            onRequestClose();
          }}
        >
          <dialog
            className={cx(css.modal, { [css.modalOpen]: isOpen }, modalClassName)}
            open={isOpen}
            onClick={event => event.stopPropagation()}
          >
            {children}
            {withCloseIcon ? (
              <Icon.Rounded
                className={css.closeIcon}
                Icon={Icon.Close}
                onClick={onRequestClose}
              />
            ) : null}
            {modalFooter()}
          </dialog>
        </section>
      </Portal>
    );
  },
);
