import React from 'react';

import cx from 'classnames';

import { AvatarUser } from '../Avatar';
import { NotificationBadge } from '../NotificationBadge';
import { NotificationBadgeType } from '../NotificationBadge/NotificationBadge';
import { Text } from '../Text';
import { LinkUser } from '../Utils/Utils';

import { UserCardProps } from './UserCard';
import css from './UserCard.module.css';

import { ButtonFollowUser } from './index';

interface UserNotificationCardProps extends UserCardProps {
  description?: string;
  canFollow?: boolean;
  type?: NotificationBadgeType;
}

export const UserNotificationCard = React.memo<UserNotificationCardProps>(
  ({ user, isMe = false, type, canFollow = false, description, refetchQueries }) => {
    return (
      <div className={css.userNotificationCardContainer}>
        <section className={css.infoNotificationContainer}>
          <LinkUser
            className={cx(
              css.cardAvatarContainer,
              css.notificationcardAvatarContainer,
            )}
            username={user.username}
          >
            <AvatarUser className={css.avatar} user={user} />
            {type ? (
              <NotificationBadge className={css.notificationBadget} type={type} />
            ) : null}
          </LinkUser>
          <article
            className={cx(css.titlesContainer, css.titlesNotificationContainer)}
          >
            <div className={css.mainNotificationCardInfo}>
              <LinkUser username={user.username}>
                <Text.Regular semibold>{user.username}</Text.Regular>
              </LinkUser>
              {/* <PashaRatingBadge
                iconClassName={css.ratingNotificationBadget}
                amount={user.rating}
              /> */}
            </div>
            <div className={css.statContainer}>
              <Text.Regular className={css.statOption} size={3}>
                {description}
              </Text.Regular>
            </div>
          </article>

          {!isMe && canFollow ? (
            <ButtonFollowUser
              playerEventCategory="invites"
              refetchQueries={refetchQueries}
              user={user}
            />
          ) : null}
        </section>
      </div>
    );
  },
);
